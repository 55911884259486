.titleNet
{
    margin-top: 50px;
    width:100%;
    height:50px;
    text-align: center;
    margin-bottom: 50px;
}
.titleNet >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 25px;
/* or 62% */

text-align: center;
letter-spacing: 0.05em;

color: #050B93;
}
.imagineNet
{
    width: 100%;
    height:500px;
    display: flex;
    justify-content: center;
   background-color: rgba(5, 11, 147, 0.12);
}
.copilImagineNet
{  display: inline-block;
   height:500px;
   width: 50%;
    background: url('../Images/local-area-network-LAN.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px 0px 100px 100px ;
}
.containerNet
{
    width:100%;
    height: 400px;

    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    background-color: rgba(5, 11, 147, 0.12);
}
.svNet
{
    width:60%;
    
}

.svNet > span{
    display: block;
    padding-top: 30px;
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 25px;
/* or 62% */

letter-spacing: 0.05em;

color: #050B93;

}

.svNet > p{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 33px;
/* or 103% */

letter-spacing: 0.05em;

color: #000000;
}

@media only screen and (max-width:960px)
{
    .titleNet
{
    margin:0;
   /*  margin-top: 50px; */
   padding-top: 50px;
    width:100%;
    height:50px;
    text-align: center;
    /* margin-bottom: 50px; */
    background-color:rgba(5, 11, 147, 0.12) ;
}
.titleNet >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1.5em;
line-height: 25px;
/* or 62% */

text-align: center;
letter-spacing: 0.05em;

color: #050B93;
}
.imagineNet
{
    width: 100%;
    height:500px;
    display: flex;
    justify-content: center;
   background-color: rgba(5, 11, 147, 0.12);
}
.copilImagineNet
{  display: inline-block;
   height:500px;
   width: 100%;
    background: url('../Images/local-area-network-LAN.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 110% 100%;
    border-radius: 150px 150px 120px 120px ;
}
.containerNet
{
    width:100%;
    height: auto;

    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    background-color: rgba(5, 11, 147, 0.12);
}
.svNet
{
    width:80%;
    
}

.svNet > span{
    display: block;
    padding-top: 30px;
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1.5em;
line-height: 25px;
/* or 62% */

letter-spacing: 0.05em;

color: #050B93;

}

.svNet > p{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 33px;
/* or 103% */

letter-spacing: 0.05em;

color: #000000;
}
}