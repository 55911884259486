.titluAuto
{
    margin-top: 50px;
    width: 100%;
    height:50px;
    text-align: center;
    margin-bottom: 50px;
}
.titluAuto > span{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 25px;
/* or 62% */

text-align: center;
letter-spacing: 0.05em;

color: #050B93;
}

.containerAutomatizari
{
    height:300px;
    width:100%;
  
    display: flex;
    justify-content: center;
}
.containerAutomatizari >:nth-child(1)
{
    height:300px;
    width:50%;
    margin-left: 50px;
    background:url('../Images/Business-process-automation-1024x491.jpg');
    background-repeat: no-repeat;
    background-size: 90% 110%;
    
}
.subtitluAutomatizari
{
    font-family: 'Monda';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
    /* or 62% */
    
    letter-spacing: 0.05em;
    
    color: #050B93;
    
}
.containerAutomatizari :nth-child(2)
{
    margin:auto;

    
}
.containerAutomatizari :nth-child(2) >p
{
font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 33px;
/* or 103% */

letter-spacing: 0.05em;

color: #000000;
}


.containerPLC
{
    width:100%;
    height: 300px;
    display: flex;
    justify-content: center;

    
}
.containerPLC :first-child
{
    padding-top: 50px;
    width:40%;
    height: 300px;
    margin:auto;
}

.containerPLC >:first-child >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 25px;
/* or 62% */

letter-spacing: 0.05em;

color: #050B93;
}
.containerPLC >:first-child >p
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 33px;
/* or 103% */

letter-spacing: 0.05em;

color: #000000;

}
.containerPLC >:nth-child(2)
{
    width:40%;
    height:300px ;
    background-color: pink ;
    background: url('../Images/Why-Business-Process-Automation-Is-Important.jpg');
    background-repeat: no-repeat;
    background-size: 90% 110%;
}

/* Mobile interface */
@media only screen and (max-width:960px)
{
    .titluAuto
{
    margin-top: 30px;
    width: 100%;
    height:50px;
    text-align: center;
    margin-bottom: 10px;
}
.titluAuto > span{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 2em;
line-height: 25px;
/* or 62% */

text-align: center;
letter-spacing: 0.05em;

color: #050B93;
}

.containerAutomatizari
{
    height:auto;
    width:100%;
  
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.containerAutomatizari >:nth-child(1)
{
    height:250px;
    width:100%;
    margin:0;
    background:url('../Images/Business-process-automation-1024x491.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
}
.containerAutomatizari >:nth-child(2)
{
    margin-top:20px;
    width:90%;
}
.subtitluAutomatizari
{
    font-family: 'Monda';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
    line-height: 25px;
    /* or 62% */
    
    letter-spacing: 0.05em;
    
    color: #050B93;
    
}
.containerAutomatizari :nth-child(2)
{
    margin:auto;

    
}
.containerAutomatizari :nth-child(2) >p
{
font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 25px;
/* or 103% */

letter-spacing: 0.05em;

color: #000000;
}


.containerPLC
{
    width:100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
}
.containerPLC :first-child
{
    padding-top: 50px;
    width:90%;
    height: auto;
    margin:auto;
}

.containerPLC >:first-child >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1.5em;
line-height: 25px;
/* or 62% */

letter-spacing: 0.05em;

color: #050B93;
}
.containerPLC >:first-child >p
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 23px;
/* or 103% */

letter-spacing: 0.05em;

color: #000000;

}
.containerPLC >:nth-child(2)
{
    width:100%;
    height:200px;
    background-color: pink ;
    background: url('../Images/Why-Business-Process-Automation-Is-Important.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

}