.titluDespreNoi
{
    width: 100%;
    height:50px;
    margin-top: 50px;
   
   padding-bottom: 50px;
    text-align: center;

}
.titluDespreNoi >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 25px;
/* identical to box height, or 62% */

text-align: center;
letter-spacing: 0.05em;

color: #050B93;

}

.descriereDespre
{
    width:100%;
    
    margin-bottom: 100px;
    text-align: center;
    background: rgba(5, 11, 147, 0.05);
}
.descriereDespre > p
{
display: inline-block;
width: 50%;
height: auto;
font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 40px;
/* or 111% */

text-align: center;
letter-spacing: 0.005em;

color: #000000;

}

.containerDespreNoi1
{
    width: 100%;
    display: flex;
  
    height:200px;
    justify-content:center;
    margin-bottom: 100px;
}
.containerDespreNoi1>:first-child
{
    width: 45%;
    height: 200px;
   
    text-align: center;
    margin: auto;
}
.containerDespreNoi1>:first-child >span
{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    /* or 111% */
    
    text-align: center;
    letter-spacing: -0.015em;
    
    color: #2e0a83;
    
}
.containerDespreNoi1>:nth-child(2)
{
    width: 35%;
   height:300px;
   background: url("../Images/mission.jpg");
   background-repeat: no-repeat;
   background-size: 100% 70%;
   margin: auto;
}

.containerDesoreNoi2
{
    width: 100%;
    display: flex;
  
    height:200px;
    justify-content:center;
    margin-bottom: 150px;
}
.containerDesoreNoi2 >:first-child
{
    width: 35%;
   height:300px;
   background: url("../Images/vision.jpg");
   background-repeat: no-repeat;
   background-size: 100% 70%;
   margin: auto;
}
.containerDesoreNoi2>:nth-child(2)
{
    width: 45%;
    height: 200px;
   
    text-align: center;
    margin: auto;
}
.containerDesoreNoi2>:nth-child(2) > span
{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    /* or 111% */
    
    text-align: center;
    letter-spacing: -0.015em;
    
    color: #2e0a83;
}


@media only screen and (max-width: 960px) {
    .titluDespreNoi
{
    width: 100%;
    height:50px;
    margin-top: 50px;
   
   padding-bottom: 50px;
    text-align: center;

}
.titluDespreNoi >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 25px;
/* identical to box height, or 62% */

text-align: center;
letter-spacing: 0.05em;

color: #050B93;

}

.descriereDespre
{
    width:100%;
    
    margin-bottom: 50px;
    text-align: center;
    background: rgba(5, 11, 147, 0.05);
}
.descriereDespre > p
{
display: inline-block;
width: 90%;
height: auto;
font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 30px;
/* or 111% */

text-align: center;
letter-spacing: 0.005em;

color: #000000;

}

.containerDespreNoi1
{
    width: 100%;
    display: flex;
  
    height:auto;
    justify-content:center;
    margin-bottom: 20px;
   
}
.containerDespreNoi1>:first-child
{
    width: 45%;
    height: auto;
    text-align: center;
    margin: auto;
}
.containerDespreNoi1>:first-child >span
{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 20px;
    /* or 111% */
    
    text-align: center;
    letter-spacing: -0.015em;
    
    color: #000000;
    
}
.containerDespreNoi1>:nth-child(2)
{
    width: 45%;
   height:200px;
   background: url("../Images/mission.jpg");
   background-repeat: no-repeat;
   background-size: 110% 100%;

   margin: auto;
}

.containerDesoreNoi2
{
    width: 100%;
    display: flex;
  
    height:auto;
    justify-content:center;
    margin-bottom: 50px;
}
.containerDesoreNoi2 >:first-child
{
    display: none;
    width: 30%;
   height:300px;
   background: url("../Images/vision.jpg");
   background-repeat: no-repeat;
   background-size: 110% 30%;
   margin: auto;
}
.containerDesoreNoi2>:nth-child(2)
{
    width: 100%;
    height: auto;
   background-color: rgba(5, 11, 147, 0.05);
    text-align: center;
    margin: auto;
}
.containerDesoreNoi2>:nth-child(2) > span
{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 30px;
    /* or 111% */
    
    text-align: center;
    letter-spacing: -0.015em;
    
    color: #000000;
}

}