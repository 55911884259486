*
{
    margin:none;
    padding:none;
}

@font-face {
    font-family: 'Mondana';
    src: local('Mondana'),url(../fonts/Monda/Monda-Regular.ttf) format('opentype');
}
@font-face {
    font-family: 'Inconsolata';
    src: local('Inconsolata'),url(../fonts/Inconsolata/Inconsolata-Regular.ttf) format('opentype');
}
.containerHeader
{
    height:52vw;
    width:auto;
    background: url(../Images/5486501.jpg);
    display: flex;
    flex-direction: column;
    gap:200px;
    justify-content: center;
background-repeat: no-repeat;
background-size:100% 100%;

}

.scris1
{

width: 1200px;
height: 46px;
/* position: absolute; */
/* top:20%;
left:15%; */
font-family: Mondana;
font-style: normal;
font-weight: 400;
font-size: 60px;
line-height: 40px;
/* or 83% */
padding-left: 200px;
letter-spacing: -0.015em;

color: #000000;
}
.scris2
{

width: 900px;
height: 46px;
/* position: absolute; */
/* top:40%;
left:30%; */
font-family: Inconsolata;
font-style: normal;
font-weight: 400;
padding-left: 40vw;
font-size: 40px;
line-height: 60px;
/* or 125% */

letter-spacing: -0.015em;

color: rgba(11, 17, 166, 0.79);
}
.buton3
{

    /* position: absolute; */

/* top:65%;
left:55%; */
    width: 500px;
    height: 70px;
    padding-left:50%;
font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 40px;
/* or 83% */

letter-spacing: -0.015em;

color: #000000;

}
.buton3 > button
{
    position: absolute;
    width: 500px;
    height: 80px;
    border:none;
    background: rgba(11, 17, 166, 0.79);
    color:aliceblue;
    font-size:40px;
}
.button3 :hover{
    border:4px white solid ;
}
.divOverDiv
{
    WIDTH: 72vh;
    height: 20%;
    background-color:rgba(5, 11, 147, 0.05);

    position: relative;

    left: 62%;
    top: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    block-size: fit-content;
}
.divOverDiv  :nth-child(1)
{
    font-family: Mondana;
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 40px;
padding-top: 20px;
/* identical to box height, or 111% */

text-align: center;
letter-spacing: -0.015em;

color: rgba(101, 81, 157, 0.79);
}
.divOverDiv :nth-child(3)
{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
    /* or 167% */
    text-align: center;
    letter-spacing: -0.015em;

    color: #000000;
}
.control123
{

    width:100%;
    display: block;
}
.containerMisiune
{
    width: 100%;
    height:600px;
    background-color:
    rgba(5, 11, 147, 0.05);
    margin-bottom: 100px;
    block-size: fit-content;
}
.parinteMisiune
{
    height: 100%;
    width: 60%;
    margin: auto;
    block-size: fit-content;
}
.parinteMisiune :nth-child(1)
{
    padding-top: 50px;
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 40px;
letter-spacing: -0.015em;

color: #050B93;
}
.parinteMisiune :nth-child(2)
{   padding-top: 33px;
    font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 36px;
padding-bottom: 50px;
line-height: 40px;
/* or 111% */

text-align: left;
letter-spacing: -0.015em;

color: #000000;
}

.containerNevoie
{
    height: 700px;
    width: 100%;

    margin-bottom: 60px;
}
.containerNevoie >p
{
    font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 40px;
/* or 111% */

text-align: center;
letter-spacing: -0.015em;

color: #050B93;


}
.parinteNevoie
{
    width: 75%;
    height:600px;
    margin: auto;

}
.containerImagini
{
    display: flex;
    padding-top: 50px;
    flex-direction: row;
    justify-content: center;
    gap: 100px;

}
.containerImagini > div
{
    width: 430px;
    height: 500px;
    /* background: url(imgpic.jpg); */
    display:inline-block;

/* box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
filter: blur(1px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));   */
}

.imgStanga
{
    border-radius: 200px 0px 0px 200px;
    background: url(../Images/imgpic.jpg);
    background-size: cover;
    background-position: center;
    text-align: center;
}
.imgMijloc
{
background: url(../Images/imgpic2.jpg);
background-size: cover;
background-position: center;
text-align: center;
}
.imgDreapta
{
    border-radius: 0px 200px 200px 0px;
    background: url(../Images/imgpic3.jpg);
    background-size: cover;
    background-position: center;
    block-size: fit-content;
    text-align: center;
}
.containerImagini > div > span
{ display: inline-block;
    text-align: center;
    padding-top: 100px;
    filter:contrast(1.9);
    text-decoration: solid;
    font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 40px;
/* or 111% */


letter-spacing: 0.355em;

color: #91a9d2
}
/* Mobile interface */

@media(max-width:960px)
{
    .containerHeader
    {
        height:40vh;
        width:auto;
        background: url(../Images/5486501.jpg);

    background-repeat: no-repeat;
    background-size:100% 100%;

    }
    .containerHeader
    {
        height:52vw;
        width:100%;
        background: url(../Images/5486501.jpg);
        display: flex;
        flex-direction: column;
        gap:20px;
        justify-content: center;
    background-repeat: no-repeat;
    background-size:100% 100%;

    }

    .scris1
    {

    width: 100%;
    height: 12px;
    text-align: left;
    margin-left: 100px;
    font-family: Mondana;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
    line-height: 30px;
   padding: 0;
    padding-bottom: 100px;
    letter-spacing: -0.015em;

  color: #000000;
    }
    .scris2
    {
        margin-top: 10px;
    width: auto;
    height: 46px;
    /* position: absolute; */
    /* top:40%;
    left:30%; */
    font-family: Inconsolata;
    font-style: normal;
    padding: 0;
    text-align: center;
    font-size: 1.3em;
    line-height: 20px;
    /* or 125% */

    letter-spacing: -0.015em;

    color: rgba(11, 17, 166, 0.79);
    }
    .buton3
    {

        width: 15vh;
        height: 70px;

    font-family: 'Monda';
    font-style: normal;

    font-size: 2.5em;
    line-height: 20px;


    letter-spacing: -0.015em;

    color: #ebebeb;

    }
    .buton3 > button
    {
        position: absolute;
        width: 30vw;
        height: 50px;
        border:none;
        background: rgba(11, 17, 166, 0.79);
        color:aliceblue;
        font-size:3vw;
    }
    .button3 :hover{
        border:4px white solid ;
    }
    .divOverDiv
    {
        WIDTH: 100%;
        height: 5vh;
        background-color:rgba(5, 11, 147, 0.05);
        left: 0%;
        top: 0%;
        margin-top: 20px;
        transform: translate(0%, 0%);
        display: flex;
        flex-direction: column;
        align-items: center;
        block-size: fit-content;
    }
    .divOverDiv  :nth-child(1)
    {
        text-align: center;
        margin: auto;
        font-family: Mondana;
    font-style: normal;
    font-weight: 400;
    font-size: 2vh;

    padding-top: 20px;
    /* identical to box height, or 111% */

    text-align: center;
    letter-spacing: -0.015em;

    color: rgba(101, 81, 157, 0.79);
    }
    .divOverDiv :nth-child(3)
    {
        width: 90%;
        font-family: 'Inconsolata';
        font-style: normal;
        font-weight: 400;
        font-size: 2vh;
        line-height: 20px;
        /* or 167% */
        text-align: center;
        letter-spacing: -0.015em;

        color: #000000;
    }
    .control123
    {


        display: block;
    }
    .containerMisiune
    {
        width: 100%;
        height:auto;
        background-color:
        rgba(250, 250, 250, 0.05);
        margin-bottom: 0;
        block-size: fit-content;
    }
    .parinteMisiune
    {
        height: auto;
        width: 90%;
        margin: auto;
        block-size: fit-content;
    }
    .parinteMisiune :nth-child(1)
    {
        padding-top: 50px;
        font-family: 'Monda';
    font-style: normal;
    font-weight: 400px;
    font-size: 1.5em;
    line-height: 40px;
    letter-spacing: -0.015em;

    color: #050B93;
    }
    .parinteMisiune :nth-child(2)
    {   padding-top: 33px;
        font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3em ;
    padding-bottom: 50px;
    line-height: 40px;
    /* or 111% */

    text-align: left;
    letter-spacing: -0.015em;

    color: #000000;
    }

    .containerNevoie
    {
        height: auto;
        width: 100%;

        margin-bottom: 10px;
    }
    .containerNevoie >p
    {
        font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4em;
    line-height: 40px;
    /* or 111% */

    text-align: center;
    letter-spacing: -0.015em;

    color: #050B93;


    }
    .parinteNevoie
    {
        width: 100%;
        height:auto;
        margin: auto;

    }
    .containerImagini
    {
        display: flex;
        padding-top: 50px;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin: auto;

    }
    .containerImagini>div
    {
        display:block;
        margin: auto;
        width: 70%;
        height: 200px
        /* background: url(imgpic.jpg); */



    }

    .imgStanga
    {

        border-radius: 100px 100px 00px 0px;
        background: url(../Images/imgpic.jpg);
        background-size: cover;
        background-position: center;
        text-align: center;

    }
    .imgMijloc
    {
    background: url(../Images/imgpic2.jpg);
    background-size: cover;
    background-position: center;
    text-align: center;

    }
    .imgDreapta
    {
        border-radius: 0px 0px 100px 100px;
        background: url(../Images/imgpic3.jpg);
        background-size: cover;
        background-position: center;
        block-size: fit-content;
        text-align: center;
    }
    .containerImagini > div > span

    { display: inline-block;

        text-align: center;
        padding-top: 35px;
        filter:contrast(1.9);
        text-decoration: solid;
        font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 20px;

    /* or 111% */


    letter-spacing: 0.355em;

    color: #91a9d2;
    }

}

@media(max-width:600px) 
{
    .scris1
    {

    width: 100%;
    height: 12px;
    text-align: center;
    margin-left: 100px;
    font-family: Mondana;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 30px;

   padding: 0;
    margin-left: 0;
    letter-spacing: -0.015em;

    color: #000000;
    }
    .scris2
    {
        margin-top: 10px;
    width: auto;
    height: 46px;
    /* position: absolute; */
    /* top:40%;
    left:30%; */
    font-family: Inconsolata;
    font-style: normal;
    padding: 0;
    text-align: center;
    font-size: 1em;
    line-height: 20px;
    /* or 125% */

    letter-spacing: -0.015em;

    color: rgba(11, 17, 166, 0.79);
    }
}


@media(max-width:1500px) and (min-width:960px)
{
    .scris1{
        font-size: 40px;
        margin-left:0px;
        padding-left: 0px;
        width:100%
    }
    .scris2{
        font-size: 30px;
        margin-left: 0px;
        padding-left: 0px;
        width:100%;
        display: flex;
        justify-content: flex-end;
    }
    .containerHeader{
        gap:150px;
        height: 89vh;
    }
    .containerImagini > div > span {
        display: inline-block;
        text-align: center;
        padding-top: 100px;
        filter: contrast(1.9);
        text-decoration: solid;
        font-family: 'Inconsolata';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0.355em;
        color: #91a9d2;
    }
    .containerImagini > div {
        width: 430px;
        height: 500px;
        /* background: url(imgpic.jpg); */
        display: inline-block;
    }

}