.titleSM
{
    margin-top:40px;
    width: 100%;

    height:50px;
    text-align: center;
    margin-bottom: 20px;
}

.titleSM > span
{

    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 25px;
/* identical to box height, or 62% */

text-align: center;
letter-spacing: 0.05em;

color: #050B93;

}
.subtitluSM
{padding-top: 40px;
    height:50px;
    width:80%;
    margin:auto;
}
.subtitluSM >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 25px;
/* identical to box height, or 69% */

text-align: center;
letter-spacing: 0.05em;

color: #000000;
}
.containerCefacem
{
    display: flex;
    width: 100%;
    height:481px;
    background: rgba(5, 11, 147, 0.05);
    
}
.parinteCeFacem
{
    width:40%;
    height:480px;

    display: flex;
    justify-content: right;
}
.parinteImagine
{
    width:60%;
    height: 480px;
    background: url('../Images/ManageRemoteTeam-1024x512-1.png');

    background-repeat: no-repeat;
    overflow: hidden;
    height: 100%;
    z-index: 2;

}
.copilCeFacem
{
    width:75%;
    height:479px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.copilCeFacem > span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 25px;
/* identical to box height, or 62% */

letter-spacing: 0.05em;

color: #050B93;
}
.copilCeFacem > p
{ padding-top: 20px;
    font-family: 'Monda';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    /* or 103% */
    
    letter-spacing: 0.05em;
    
    color: #000000;
     
}
.copilImagine
{
    width:1024px;
    height: 480px;
    background: rgba(5, 11, 147, 0.05);
    background-repeat: no-repeat;
    background-size: auto;
    overflow: hidden;
    height: 100%;
    z-index: 2;
}

.containerCumfacem
{
    padding-top: 50px;
    width: 100%;
    display: flex;
    padding-bottom: 50px;
}
.CumfacemImagine
{
    height:400px;
    width: 60%;
    background: url('../Images/illustration-team-meeting-manager.jpg');
    background-repeat: no-repeat;
    background-size: auto;

}
.CumfacemText
{   width: 104%;
    height: auto;
    display: flex;
    flex-direction: column; 
    justify-content: center;  
    gap: 20px;
    margin-left: 50px;
    
}

.CumfacemText >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 33px;
/* identical to box height, or 82% */

letter-spacing: 0.05em;

color: #050B93;

}
.CumfacemText >p
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 33px;
/* or 103% */

letter-spacing: 0.05em;

color: #000000;
}

@media only screen and (max-width:960px){
    .titleSM
{
    margin-top:40px;
    width: 100%;

    height:50px;
    text-align: center;
    margin-bottom: 0px;
}

.titleSM > span
{

    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1.5em;
line-height: 25px;
/* identical to box height, or 62% */

text-align: center;
letter-spacing: 0.05em;

color: #050B93;

}
.subtitluSM
{padding-top: 20px;
    height:auto;
    width:100%;
    margin:auto;
    text-align: center;
}
.subtitluSM >span
{   
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 25px;
/* identical to box height, or 69% */

text-align: center;
letter-spacing: 0.05em;

color: #000000;
}
.containerCefacem
{
    display: flex;
    width: 100%;
    height:auto;
    background: rgba(5, 11, 147, 0.05);
    justify-content: center;
    flex-direction: column;

}
.parinteCeFacem
{ margin-top: 15px;
    margin-bottom: 15px;
    width:100%;
    height:auto;
    display: flex;
    justify-content: center;
}
.parinteImagine
{
    width:100%;
    height: 200px;
    background: url('../Images/ManageRemoteTeam-1024x512-1.png');
background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: -2;
 
}
.copilCeFacem
{
    width:80%;
    height:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.copilCeFacem > span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1.2em;
line-height: 25px;
/* identical to box height, or 62% */

letter-spacing: 0.05em;

color: #050B93;
}
.copilCeFacem > p
{ padding-top: 20px;
    font-family: 'Monda';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 25px;
    /* or 103% */
    
    letter-spacing: 0.05em;
    
    color: #000000;
     
}
.copilImagine
{
    display: none;
    width:1024px;
    height: 480px;
    background: rgba(5, 11, 147, 0.05);
    background-repeat: no-repeat;
    background-size: auto;
    overflow: hidden;
    height: 100%;
    z-index: 2;
}

.containerCumfacem
{
    padding-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 50px;
}
.CumfacemImagine
{
    height:200px;
    width: 100%;
    background: url('../Images/illustration-team-meeting-manager.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;

}
.CumfacemText
{   width: 100%;
    height: auto;
    display: flex;
    flex-direction: column; 
    justify-content: center;  
    gap: 10px;
    margin-left: 0px;
    
}

.CumfacemText >span
{
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 1.2em;
line-height: 25px;
/* identical to box height, or 82% */

letter-spacing: 0.05em;

color: #050B93;
margin-left: 10px;
}
.CumfacemText >p
{
font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 0.8em;
line-height: 25px;
/* or 103% */
margin-left: 10px;
letter-spacing: 0.05em;

color: #000000;
}
    
}