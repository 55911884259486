*
{
    margin:none;
    padding: none;
}

.backgroundServicii
{
    width:100%;
    margin:auto;
    height: 200px;
    /* background: url(../Images/contactbksImage.jpg);  */
    background-position: 50% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backgroundServicii >span
{
    font-family: 'Monda';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 40px;
    /* identical to box height, or 62% */
    
    text-align: center;
    letter-spacing: 0.075em;
    
    color: rgba(11, 17, 166, 0.79);
}
.containerCategorii
{
    height:403px;
    width:100%;
    
    display: flex;
    justify-content: center;
    gap:50px;
    text-align: center;
}

.containerCategorii > div{
    height:290px;
    width: 290px;
    margin-top: 50px;
    
}
.containerCategorii > div:hover{
 
    background-color:rgba(5, 11, 147, 0.05); ;   
}
.containerCategorii > div >div >img{
   margin-top: 10px;
    width:200px;
}

.containerCategorii>Link >div  >span{
    margin-top: 20px;
    font-size: 20px;

}
img{
    height: 220px;
    width: 230px;
}

@media only screen and (max-width: 600px)
{
    
.backgroundServicii
{
    width:100%;
    margin:auto;
    height: 100px;
    /* background: url(../Images/contactbksImage.jpg);  */
    /* background-position: 50% 5%; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.backgroundServicii >span
{
    font-family: 'Monda';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    line-height: 40px;
    /* identical to box height, or 62% */
    
    text-align: center;
    letter-spacing: 0.075em;
    
    color: rgba(11, 17, 166, 0.79);
}
.containerCategorii
{
    height:auto;
    width:100%;
    
    display: flex;
    justify-content: center;
    gap:10px;
    text-align: center;
    flex-direction: row;
    margin-bottom: 50px;
}

.containerCategorii > div{
    height:auto;
    width: 80px;
    margin-top:0;
    /* margin-top: 50px; */
   
}
.containerCategorii > div:hover{
 
    background-color:rgba(5, 11, 147, 0.05); ;   
}
.containerCategorii > div >div >img{
   margin-top: 10px;
    width:4rem;
    height: 4rem;
}

.containerCategorii>Link >div  >span{
    margin-top: 20px;
    font-size: 3px;

}
.containerCategorii>div>span {
font-size: 13px;
text-align: left;
font-family: 'Monda';
};
img{
    height: 220px;
    width: 230px;
}

}