.containerSD
{
    width:100%;
    height:auto;
    background: rgba(5, 11, 147, 0.05);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 60px;
}
.containerSD :first-child{
    display:flex;
    justify-content: center;
}
.containerSD :first-child >span
{
font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 40px;
padding-top: 50px;
/* or 111% */

text-align: center;
letter-spacing: 0.11em;

color: #050B93;
}

.parinteSD{
    width: 70%;
    
    height:400px;
    margin: auto;
    padding-top: 50px;
}
.parinteSD >p{
    font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 40px;
/* or 125% */
padding-left: 50px;

letter-spacing: 0.11em;

color: #050B93;
}
.parinteSD >span{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    /* or 200% */
    
    letter-spacing: 0.11em;
    
    color: #000000;
   
}
 .redirectPagina
{   
    
    display:flex;
    
    justify-content: flex-end;
    width:50%;
    height:50px;
    margin:auto;

}

.redirectPagina >button
{
    width:300px;
    
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 40px;
/* identical to box height, or 125% */

letter-spacing: -0.015em;

color: #FFFFFF;
background: #0B11A6;
border-style: none;
}

@media only screen and (max-width:600px) {
    .containerSD
{
    width:100%;
    height:auto;
    background: rgba(5, 11, 147, 0.05);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 60px;
}
.containerSD :first-child{
    display:flex;
    justify-content: center;
    width:90%;
    margin:auto;
}
.containerSD :first-child >span
{
font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 1.4em;
line-height: 25px;
padding-top: 20px;

/* or 111% */

text-align: center;
letter-spacing: 0.11em;

color: #050B93;
}

.parinteSD{
    width: 95%;
    
    height:auto;
    margin: auto;
    padding-top: 50px;
}
.parinteSD >p{
    font-family: 'Inconsolata';
font-style: normal;
font-weight: 400;
font-size: 1.5em;
line-height: 20px;
/* or 125% */
padding-left: 10px;

letter-spacing: 0.11em;

color: #050B93;
}
.parinteSD >span{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 20px;
    /* or 200% */
    
    letter-spacing: 0.11em;
    
    color: #000000;
   
}
 .redirectPagina
{   
    
    display:flex;
    
    justify-content: flex-end;
    width:50%;
    height:50px;
    margin:auto;

}

.redirectPagina >button
{
    width:300px;
    
    font-family: 'Monda';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 40px;
/* identical to box height, or 125% */

letter-spacing: -0.015em;

color: #FFFFFF;
background: #0B11A6;
border-style: none;
}
}