*{
    margin:0;
    padding:0;
    text-decoration: none;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter'),url(../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf) format('opentype');
}

a{
    color:#000000;
}
a:hover
{
    color:#050B93;
}
.container
{
width: 100%;
height: 103px;

display: flex;
justify-content: center;


}
.parent{
    width: 65%;
    height: 103px;
    
    display: flex;
    justify-content: space-between;
   margin:auto;
    
}

.child1
{
    display: inline;
   
    display:flex;
    align-items: center;
    gap:40px;
}
.child2
{
    display:inline;
    
    display:flex;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    /* identical to box height, or 200% */
    
    text-align: center;
    letter-spacing: -0.015em;
    
    color: #000000;
}
.divButoaneNavigare{
    display:inline-block;
    
}
.listaButoane
{
    list-style-type: none;
    display: flex;
    gap: 37px;

    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 40px;
/* identical to box height, or 200% */

text-align: center;
letter-spacing: -0.015em;

color: #000000;

}

.logoDiv
{
    
    display:inline-block;
    max-width: 180px;
    
    text-align: center;
    
}
.logo{
    font-family: Inter;
    font-style: normal;
    font-weight: 400px;
    font-size: 26px;
    line-height: 30px;
    /* or 111% */
    
    text-align: center;
    letter-spacing: -0.015em;
    
    color: #050B93;
    
    
    
}
.subMenu{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height:auto;
  width: 300px;
  text-align: left;
    background-color:rgb(155, 155, 245);
  margin-top: 200px;
  margin-left: 350px;
 
}
.subMenu >li{
  border-bottom:2px solid rgba(79, 99, 171, 0.57);
  width: 90%;
  margin-top: 0px;

}
.subMenu >li:hover{
  transform: scale(1.1);
 
}
/* Style mobile interface */


.mobileNavbar
{
    display: none;
 
   width: fit-content;
   block-size: fit-content;
   background-color: rgb(74, 74, 187);;
}
#hamburger-icon {
    margin: 0;
    display: none;
    cursor: pointer;
 
  }
  
  #hamburger-icon div {
    width: 35px;
    height: 3px;
    background-color: rgb(241, 241, 243);
    margin: 6px 0;
    
  }
  
  .open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
  }
  
  .open .bar2 {
    opacity: 0;
  }
  
  .open .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -8px);
  }
  ul {
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  #login,
  #signup {
    border-radius: 5px;
    padding: 5px 8px;
  }
  
  #login {
    border: 1px solid #498afb;
  }
  
  #signup {
    border: 1px solid #ff3860;
  }
  
  #signup a {
    color: #ff3860;
  }
  
  #login a {
    color: #498afb;
  }
 
@media only screen and (max-width: 960px) {
    /* #hamburger-icon
{
    display:block;
}
    .container
    {
        background-color: aqua;
        height: auto;
        width: 100%;
    }
  
    .parent {
        width: 100%;
        height: auto;
        justify-content: center;
        display: flex;
        flex-direction:column ;
        
        }
    .child1
    {
       flex-direction: column;
       gap:2px;
    }
    .listaButoane
    {
        flex-direction: column;
        gap:2px;
    }
   .nav{
    flex-direction: column;
   }
   .child2
   {
    display: flex;
    flex-direction: column;
   }
    */
   
    
    .container {
        display: none;
      }
    
    .mobileNavbar{
        display: block;
        height: auto;
        width: 100%;
      
        background: rgba(79, 99, 171, 0.57);
      margin-top:-5px;
      margin-bottom: 0px;
      padding: -2px;
       
      }
      .titleMobileNavbar{
        display: inline-block;
        font-size: 20px;
        width: 80%;
        margin:auto;
        padding-bottom: 15px;
        text-align: center;
      }
    #hamburger-icon
    {
      display: flex;
      justify-content: left;
      width: 10%;
        display: inline-block;
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
       height:15px;
       
       

    }
      .mobile-menu {
        list-style: none;
        height: 100%;
        display: flex;
        align-items:flex-start;
        padding-bottom: 15px;
        padding-top: 15px;
      }
     
      ul a {
        color: black;
      }
      
      ul li {
        padding: 0px;
        margin-left:0px;
      }
      
      ul li:hover {
        transform: scale(1.1);
       
      }

.subMenu
{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height:auto ;
  width:200px;
  text-align: left;
  background-color:rgb(155, 155, 245);
  gap:10px;
  margin-top: 35px;
  margin-left: 105px;
  
}
.subMenu >li{
  border-bottom:2px solid rgba(79, 99, 171, 0.57);
  width: 90%;
  margin-top: 5px;

}
.subMenu >li:hover{
  transform: scale(1.1);
 
}
}