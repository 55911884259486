.continer-web-dev
{padding-top: 50px;
    width: 80%;
height: auto;
margin: auto;


}
.titleSD 
{
    width: 100%;
    height:50px;
   text-align: center;

}
.titleSD >span
{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 48px;
line-height: 40px;
/* identical to box height, or 83% */

text-align: center;
letter-spacing: -0.015em;

color: #050B93;
}

.webDev>span{
    padding-top: 50px;
    font-family: 'Inter';
    display: inline-block;
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 40px;
/* identical to box height, or 100% */

text-align: center;
letter-spacing: -0.015em;

color: #050B93;
 
}
.webDev> p
{
margin-left:10px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 40px;
/* or 125% */
text-align-last:left;
letter-spacing: -0.015em;

color: #000000;
}
.parinteEtape
{
    width: 100%;
    display: flex;
    padding-top: 25px;
}
.copil-etape-1
{
    height:auto;
    width: 57%;
    height:400px;
    
}
.copil-etape-1 >span
{padding-top: 20px;
    font-family: 'Inter';
    display: inline-block;
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 40px;
/* identical to box height, or 100% */

text-align: center;
letter-spacing: -0.015em;


}
.copil-etape-1>ul
{
    padding-top: 20px;
    list-style-type: none;
}
.copil-etape-1 >ul >li
{
padding-top: 10px;
padding-left: 50px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 40px;
/* or 125% */

letter-spacing: -0.015em;

color: #000000;

}
.copil-etape-2
{
    width:40%;
    height:400px;
    background: url('../Images/webIMG.jpg');
    background-position: 50% 60%;
    background-size: 100% 100%;
}

/* Baze de date */

.container-baze-date
{
    width:100%;
    height:400px;
    margin:auto;
    margin-bottom: 50px;
}
.container-baze-date >p{
    font-family: 'Inter';

font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 40px;
/* identical to box height, or 100% */
letter-spacing: -0.015em;

color: #050B93;
width: 80%;
margin:auto;
}
.parinte-baze-date
{
    display:flex;

}
.parinte-baze-date>:nth-child(1)
{
    height:350px;
    width:45%;
    background:url(../Images/The-Most-Popular-Databases-in-2020.jpg);
    background-size: 100% 100%;

}
.parinte-baze-date>:nth-child(2) 
{
    height:350px;
    width:55%;
    background: rgba(5, 11, 147, 0.05);
    
}
.parinte-baze-date>:nth-child(2) > p
{
  width: 80%;
  padding-top: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  /* or 125% */
  padding-left: 20px;
  letter-spacing: -0.015em;
  
}

@media only screen and (max-width:960px)
{
    .continer-web-dev
{padding-top: 50px;
    width: 90%;
height: auto;
margin: auto;


}
.titleSD 
{
    width: 100%;
    height:50px;
   text-align: center;

}

.titleSD >span
{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 2em;
line-height: 20px;
/* identical to box height, or 83% */

text-align: center;
letter-spacing: -0.015em;

color: #050B93;
}

.webDev>span{
    padding-top: 50px;
    font-family: 'Inter';
    display: inline-block;
font-style: normal;
font-weight: 400;
font-size: 1.3em;
line-height: 20px;
/* identical to box height, or 100% */

text-align: center;
letter-spacing: -0.015em;

color: #050B93;
 
}
.webDev> p
{
margin-left:10px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 20px;
/* or 125% */
text-align-last:center;
letter-spacing: -0.015em;

color: #000000;
}
.parinteEtape
{
    width: 100%;
    display: flex;
    padding-top: 25px;
    flex-direction:column-reverse;
    justify-content: center;
}
.copil-etape-1
{
    height:auto;
    width: 100%;
    height:auto;
    
}
.copil-etape-1 >span
{padding-top: 20px;
    font-family: 'Inter';
    display: inline-block;
font-style: normal;
font-weight: 400;
font-size: 1.2em;
line-height: 20px;
/* identical to box height, or 100% */

text-align: center;
letter-spacing: -0.015em;


}
.copil-etape-1>ol
{
    padding-top: 20px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.copil-etape-1 >ol >li
{
padding-top: 10px;
padding-left: 50px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 20px;
/* or 125% */

letter-spacing: -0.015em;

color: #000000;

}
.copil-etape-2
{
    width:80%;
    height:200px;
    background: url('../Images/webIMG.jpg');
    background-position: 50% 60%;
    background-size: 100% 100%;
}

/* Baze de date */

.container-baze-date
{padding-top: 20px;
    width:100%;
    height:auto;
    margin:auto;
    margin-bottom: 50px;
}
.container-baze-date >p{
    font-family: 'Inter';

font-style: normal;
font-weight: 400;
font-size: 1.2em;
line-height: 20px;
/* identical to box height, or 100% */
letter-spacing: -0.015em;

color: #050B93;
width: 80%;
margin:auto;
}
.parinte-baze-date
{
    display:flex;
    flex-direction: column-reverse;
justify-content: center;

}
.parinte-baze-date>:nth-child(1)
{
    height:200px;
    width:100%;
    background:url(../Images/The-Most-Popular-Databases-in-2020.jpg);
    background-size: 100% 100%;

}
.parinte-baze-date>:nth-child(2) 
{
    height:auto;
    width:100%;
    background: rgba(5, 11, 147, 0.05);
    
}
.parinte-baze-date>:nth-child(2) > p
{
  width: 80%;
  padding-top: 20px;
  padding-bottom:20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 20px;
  /* or 125% */
  padding-left: 20px;
  letter-spacing: -0.015em;

}
}