*{
    margin:0;
    padding: 0;
}
.invalid{
    border: 3px solid rgba(43, 161, 96, 0.486);
}
.containerContact
{
    width:100%;
    margin:auto;
    height: 200px;
    /* background: url(../Images/abstract-connections-banner-design_1048-10068.jpg); */
    background-repeat: no-repeat;
    background-size:100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerContact >span
{
    font-family: 'Monda';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 40px;
    /* identical to box height, or 62% */
    
    text-align: center;
    letter-spacing: 0.075em;
    
    color: rgba(11, 17, 166, 0.79);
}
.rugaminte
{
  width:50%;
  height:50px;
  
  margin:auto;
}
.rugaminte > p
{
display: inline-block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 25.5px;
line-height: 40px;
/* identical to box height, or 167% */

 letter-spacing: 0.02em;

color: #000000; 
}
.containerFormular
{
    width: 100%;
    
   padding-bottom: 100px;
}
.parinteFormular
{
    width:100%;
    height:600px;
    margin:auto;
   
    display: flex;
    
}
.parinteInputs
{
    padding-left: 200px;
    height:100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap:35px;
    justify-content:center;
    align-items: center;
 
   background: rgba(5, 11, 147, 0.05);
}
.parinteInputs > input  ,select
{
    width:500px;
    height: 40px;
    font-size:20px;
    box-sizing: border-box;
    background: #FFFFFF;
border: 1px solid rgba(71, 12, 159, 0.42);
}
.parinteInputs > textarea
{
    font-size:20px;
    width: 500px;
    height:60px;
    background: #FFFFFF;
border: 1px solid rgba(17, 21, 130, 0.42);
}
.btnForm
{
    width:500px;
    height:40px;
    display: flex;
  
   justify-content: right;
}
.btnForm > button
{
    width: 250px;
    height:40px;
   background: rgba(5, 11, 147, 0.12);
   border: none;
}
.btnForm > button >span
{
font-size: 20px;
}
.btnForm >button:hover
{
 background: rgba(79, 99, 171, 0.57);
}
.parinteInputsNP
{
    width: 500px;
    display: flex;
    justify-content: space-between;
    
}
.parinteInputsNP >input
{
    font-size:20px;
    width: 240px;
    height:40px;
    background: #FFFFFF;
border: 1px solid rgba(17, 21, 130, 0.42);
    
}
.parinteDateContact
{
    
    height:100%;
    width:40%;
    background: rgba(5, 11, 147, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   gap:50px;
  
}
.parinteDateContact > div
{

margin-left: 30px;
}
.parinteDateContact > div >span
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    /* identical to box height, or 167% */
    
    letter-spacing: 0.02em;
    
    color: #050B93;
}

@media only screen and (max-width:960px)
{
    .containerContact
{
    width:100%;
    margin:auto;
    height: auto;
    /* background: url(../Images/abstract-connections-banner-design_1048-10068.jpg); */
   
    background-repeat: no-repeat;
    background-size:100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.containerContact >span
{
    font-family: 'Monda';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    line-height: 40px;
    /* identical to box height, or 62% */
    
    text-align: center;
    letter-spacing: 0.075em;
    
    color: rgba(11, 17, 166, 0.79);
}
.rugaminte
{
    margin-top: 20px;
  width:90%;
  height:50px;

  margin:auto;
  display: flex;
  justify-content: center;

}
.rugaminte > p
{
text-align: center;
display: inline-block;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 40px;
/* identical to box height, or 167% */
 letter-spacing: 0.02em;
color: #000000; 
}
.containerFormular
{
    width: 100%;
    margin-top: 15px;
   padding-bottom: 100px;
}
.parinteFormular
{
    
    width:100%;
    height:600px;
    margin:auto;
   
    display: flex;
    flex-direction: column;
  
    justify-content: center;
}
.parinteInputs
{
    padding-top: 10px;
    padding-left: 0px;
    height:100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:30px;
    justify-content:center;
    align-items: center;
    background: rgba(5, 11, 147, 0.05);
}
.parinteInputs > input  ,select
{
    width:90%;
    height: 40px;
    font-size:20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid rgba(71, 12, 159, 0.42);
}
.parinteInputs > textarea
{
    font-size:20px;
    width: 90%;
    height:60px;
    
    background: #FFFFFF;
border: 1px solid rgba(17, 21, 130, 0.42);
border-radius: 0px;
}
.btnForm
{
    margin-bottom: 10px;
    width:100%;
    height:40px;
    display: flex;
  
   justify-content: center;
}
.btnForm > button
{
    width: 250px;
    height:40px;
   background: rgba(5, 11, 147, 0.12);
   border: none;
}
.btnForm > button >span
{
font-size: 20px;
}
.btnForm >button:hover
{
 background: rgba(79, 99, 171, 0.57);
}
.parinteInputsNP
{
    width: 90%;
    display: flex;
    justify-content: space-between;
    
}
.parinteInputsNP >input
{
    font-size:20px;
    width: 45%;
    height:40px;
    background: #FFFFFF;
border: 1px solid rgba(17, 21, 130, 0.42);
}
.parinteDateContact
{
    margin-top: 35px;
    
    
    height:auto;
    width:100%;
    background: rgba(5, 11, 147, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    justify-content: center;
   gap:5px;
}
.parinteDateContact > div
{
margin-top: 15px;
margin-left: 10px;
width: 100%;
margin:auto;
display: flex;
justify-content: center;

}
.parinteDateContact > div >span
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size:1.2em;
    line-height: 40px;
    /* identical to box height, or 167% */
    
    letter-spacing: 0.02em;
    
    color: #050B93;
}
}