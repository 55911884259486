*{
    margin:0;
    padding: 0;
}

.containerFooter
{
    
    
}
.numeEchipa
{
    width:100%;
    background: linear-gradient(180deg, rgba(11, 17, 166, 0.158) 0%, rgba(0, 0, 0, 0) 100%);


}
.numeEchipa > p{
    font-family: 'Inconsolata';
    font-style: normal;
  
    font-size: 36px;
    line-height: 40px;
    /* identical to box height, or 111% */
    
    text-align: center;
    letter-spacing: 0.205em;
    
    color: rgba(11, 17, 166, 0.71);
}

.politicaSubsol
{
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    
background: rgba(79, 99, 171, 0.57);
}
.politicaSubsolctrl
{
    width:80%;
    height:auto;
    
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin:auto;
    gap:50px;
   
    
}
.politicaSubsolctrl > div
{

    width: 305px;
    display: inline;
    
    align-items: center;
}
.politicaSubsolctrl > div >span 
{
    display: table;
    margin:auto;

}
.copyRightContainer
{
    width:100%;
    
display: flex;
justify-content: center;
background: rgba(90, 92, 147, 0.54);
}

.cpyRights{
display: inline-block;
}
.social
{
    display: inline-block;
}
.parent2
{   width: 80%;
    display: flex;
    margin:auto;
    justify-content: space-between;
}
.sectionTitle
{
    font-family: 'Inconsolata';
font-style: normal;
font-size: 20px;
line-height:25px;
/* or 111% */

text-align: center;
letter-spacing: -0.015em;

color: rgba(11, 17, 166, 0.71);
}

@media(max-width:600px)
{
    .politicaSubsolctrl
{
    width:80%;
    height:auto;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin:auto;
    gap:50px;
   
   
}
}